import React, { useCallback, useEffect, useRef, useState } from 'react';
import type { FC } from 'react';
import styled from 'styled-components';

import ArrowDownIcon from 'assets/icons/arrow-drop-down-down.svg';
import ArrowRightIcon from 'assets/icons/arrow-drop-down-right.svg';
import { tablet } from 'styles/breakpoints';

import { DynamicImage, Text } from 'components';

import Layout from 'layouts/Layout';

interface Item {
  question: string;
  answer: string[];
}

interface PeopleAskUsProps {
  title: string;
  list: Item[];
}

const MainContainer = styled.div`
  width: 100vw;
  background-size: cover;
`;

const Container = styled.div`
  margin: auto;
  max-width: 100%;
  padding: 0 32px;
  width: 1204px;
  display: flex;
  flex-direction: column;

  align-items: center;
  @media ${tablet} {
    padding-left: 16px;
    padding-right: 16px;
  }
`;

const QuestionContainer = styled.div<{ expanded: boolean }>`
  overflow: ${({ expanded }) => (expanded ? 'auto' : 'hidden')};
  ::-webkit-scrollbar {
    width: 0;
  }
  transition: max-height 0.6s ease;
  border-radius: 1rem;
  background-color: ${({ theme }) => theme.colors.light0};
  cursor: pointer;
  padding: 1.5rem 1.5rem 0;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 8px;
  border-bottom: 1px solid #e4e4eb;
  justify-content: space-between;
`;

const Title = styled(Text)`
  text-align: center;
  font-size: 2.375rem;
  font-family: Inter;
  font-weight: 500;

  @media ${tablet} {
    font-weight: 600;
    font-size: 1.5rem;
  }
`;

const Question = styled(Text).attrs({
  type: 'tabActive',
  color: 'dark80',
})`
  font-size: 1.125rem;
  transition: color 0.2s ease;
  font-family: Inter;
  font-weight: 500;
  &:hover {
    color: ${({ theme }) => theme.colors.dark60};
  }
  /* fixes large text on ios issue */
  -webkit-text-size-adjust: none;
`;

const List = styled.div`
  margin-top: 3rem;
  max-width: 100%;
  width: 832px;
  div:nth-child(1n):not(:last-child) {
    margin-bottom: 1.5rem;
  }

  @media ${tablet} {
    margin-top: 2rem;
  }
`;

const AnswerContainer = styled.div<{ expanded: boolean }>`
  max-height: ${({ expanded }) => (expanded ? 700 : 0)}px;
  transition: max-height 0.4s ease;
  margin-top: 1.06rem;
  /* hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-y: auto;
  /* hide scrollbar for Chrom, Safari */
  ::-webkit-scrollbar {
    width: 0;
    display: none;
  }
`;

const ArrowDown = styled(ArrowDownIcon)`
  max-width: 1.5rem;
  min-width: 1.5rem;
`;

const ArrowUp = styled(ArrowRightIcon)`
  max-width: 1.5rem;
  min-width: 1.5rem;
  rotate: 270deg;
`;

const Answer = styled(Text)`
  padding-bottom: 1.5rem;
  font-family: Inter;
  font-weight: 300;
  -webkit-text-size-adjust: 100%;
`;

const FAQ: FC<PeopleAskUsProps> = () => {
  const [expandedQuestions, setExpandedQuestions] = useState<number[]>([]);
  const childRef = useRef<HTMLDivElement>(null);

  const handleClick = useCallback(
    (index: number) => {
      if (expandedQuestions.includes(index)) {
        setExpandedQuestions(prevState => [
          ...prevState.filter(item => item !== index),
        ]);
        return;
      }
      setExpandedQuestions(prevState => [...prevState, index]);
    },
    [expandedQuestions],
  );

  useEffect(() => {
    if (!childRef.current) return;
  }, [childRef, expandedQuestions]);

  const isExpanded = (index: number) => expandedQuestions.includes(index);

  return (
    <Layout>
      <Wrapper>
        <Container>
          <Title>Your questions, answered</Title>
          <List>
            <QuestionContainer expanded={isExpanded(1)}>
              <TitleContainer onClick={() => handleClick(1)}>
                <Question>
                  What sets Nova&apos;s formula apart from others?
                </Question>
                {isExpanded(1) ? <ArrowUp /> : <ArrowDown />}
              </TitleContainer>

              <AnswerContainer expanded={isExpanded(1)}>
                <div ref={childRef}>
                  <Answer>
                    For starters, our product is crafted in an FDA-registered
                    lab right here in the USA, ensuring high quality. , track
                    your medications intake, and set up reminders.
                  </Answer>
                  <Answer>
                    Nova is created with both anti-aging and weight loss in
                    mind. Spermidine boosts metabolism and reverses biological
                    aging, while thiamine enhances the metabolism even more and
                    reduces fat storage. Additionally, Zinc is known to improve
                    skin, hair, and nail health, as well as enhance the overall
                    efficacy of the supplement. This combination offers a
                    multifaceted, anti-aging approach to weight management,
                    setting it apart from 97% of other standard supplements in
                    the wellness industry.
                  </Answer>
                </div>
              </AnswerContainer>
            </QuestionContainer>
            <QuestionContainer expanded={isExpanded(2)}>
              <TitleContainer onClick={() => handleClick(2)}>
                <Question>
                  How fast can I expect to see any noticeable results?
                </Question>
                {isExpanded(2) ? <ArrowUp /> : <ArrowDown />}
              </TitleContainer>

              <AnswerContainer expanded={isExpanded(2)}>
                <div ref={childRef}>
                  <Answer>
                    Everyone&apos;s body responds uniquely. Results can vary
                    from person to person, but typically, it takes about 1 week
                    for a noticeable metabolism-boosting process to begin.
                  </Answer>
                  <Answer>
                    To experience the full range of benefits, we recommend
                    consistently taking Nova for at least 3 months. Here&apos;s
                    a glimpse of what you can look forward to shortly after you
                    begin using it:
                  </Answer>
                </div>
              </AnswerContainer>
            </QuestionContainer>
            <QuestionContainer expanded={isExpanded(3)}>
              <TitleContainer onClick={() => handleClick(3)}>
                <Question>What ingredients are used in Nova?</Question>
                {isExpanded(3) ? <ArrowUp /> : <ArrowDown />}
              </TitleContainer>

              <AnswerContainer expanded={isExpanded(3)}>
                <div ref={childRef}>
                  <Answer>Serving size: 2 capsules</Answer>
                  <StyledImage alt="im" src={'checkout/sup_facts.png'} />
                  <Answer>
                    1 bottle has 30 servings (60 capsules) for 30 days
                  </Answer>
                  <Answer>
                    <strong>Suggested usage:</strong> take two capsules with
                    water during your meal, one time per day.
                  </Answer>
                  <Answer>
                    <strong>Caution:</strong> Consult with your physician if you
                    have any medical conditions. Like most other dietary
                    supplements, Nova is not suitable for pregnant women,
                    breastfeeding mothers, or children, but we are hoping to
                    conduct comprehensive tests in the near future to make our
                    product safe for that demographic. Stop taking the
                    supplement and speak with your physician for advice if you
                    experience mild allergic-type reactions such as nose or eye
                    irritation, feeling short of breath, or itchy skin. If you
                    experience any other uncommon symptoms which you think may
                    be due to taking the supplement, speak with your physician
                    for further advice.
                  </Answer>
                  <Answer>
                    <strong>Storage:</strong> Do not use if safety seal is
                    damaged or missing. Store in a cool, dry place, away from
                    heat/moisture.
                  </Answer>
                </div>
              </AnswerContainer>
            </QuestionContainer>
            <QuestionContainer expanded={isExpanded(4)}>
              <TitleContainer onClick={() => handleClick(4)}>
                <Question>Why spermidine is important?</Question>
                {isExpanded(4) ? <ArrowUp /> : <ArrowDown />}
              </TitleContainer>

              <AnswerContainer expanded={isExpanded(4)}>
                <div ref={childRef}>
                  <Answer>
                    Spermidine is a natural compound that initiates weight loss
                    by activating autophagy in the body. This boosts metabolism,
                    reverses biological aging, and makes weight loss easy.
                  </Answer>
                </div>
              </AnswerContainer>
            </QuestionContainer>
            <QuestionContainer expanded={isExpanded(5)}>
              <TitleContainer onClick={() => handleClick(5)}>
                <Question>
                  Will my metabolism slow down when I stop using Nova
                  supplements?
                </Question>
                {isExpanded(5) ? <ArrowUp /> : <ArrowDown />}
              </TitleContainer>

              <AnswerContainer expanded={isExpanded(5)}>
                <div ref={childRef}>
                  <Answer>
                    After discontinuing Nova, your metabolism may gradually
                    return to its previous rate, and the anti-aging benefits may
                    diminish. The weight you lost while using Nova might not be
                    maintained. The duration of these effects can vary,
                    especially if you&apos;ve been using Nova for a long time,
                    as your body remains nutrient-dense from prolonged use.
                  </Answer>
                </div>
              </AnswerContainer>
            </QuestionContainer>
            <QuestionContainer expanded={isExpanded(6)}>
              <TitleContainer onClick={() => handleClick(6)}>
                <Question>Can I use Nova Spermidine while on a diet?</Question>
                {isExpanded(6) ? <ArrowUp /> : <ArrowDown />}
              </TitleContainer>

              <AnswerContainer expanded={isExpanded(6)}>
                <div ref={childRef}>
                  <Answer>
                    Yes, you can totally use spermidine and follow any diet. In
                    fact, spermidine might even help you boost your diet by
                    improving your metabolism.
                  </Answer>
                </div>
              </AnswerContainer>
            </QuestionContainer>
            <QuestionContainer expanded={isExpanded(7)}>
              <TitleContainer onClick={() => handleClick(7)}>
                <Question>
                  Will my metabolism slow down when I stop using Nova
                  supplements?
                </Question>
                {isExpanded(7) ? <ArrowUp /> : <ArrowDown />}
              </TitleContainer>

              <AnswerContainer expanded={isExpanded(7)}>
                <div ref={childRef}>
                  <Answer>
                    After discontinuing Nova, your metabolism may gradually
                    return to its previous rate, and the anti-aging benefits may
                    diminish. The weight you lost while using Nova might not be
                    maintained. The duration of these effects can vary,
                    especially if you&apos;ve been using Nova for a long time,
                    as your body remains nutrient-dense from prolonged use.
                  </Answer>
                </div>
              </AnswerContainer>
            </QuestionContainer>
            <QuestionContainer expanded={isExpanded(8)}>
              <TitleContainer onClick={() => handleClick(8)}>
                <Question>Can I use Nova Spermidine while on a diet?</Question>
                {isExpanded(8) ? <ArrowUp /> : <ArrowDown />}
              </TitleContainer>

              <AnswerContainer expanded={isExpanded(8)}>
                <div ref={childRef}>
                  <Answer>
                    Yes, you can totally use spermidine and follow any diet. In
                    fact, spermidine might even help you boost your diet by
                    improving your metabolism.
                  </Answer>
                </div>
              </AnswerContainer>
            </QuestionContainer>
            <QuestionContainer expanded={isExpanded(9)}>
              <TitleContainer onClick={() => handleClick(9)}>
                <Question>
                  How long will it take to deliver my package?
                </Question>
                {isExpanded(9) ? <ArrowUp /> : <ArrowDown />}
              </TitleContainer>

              <AnswerContainer expanded={isExpanded(9)}>
                <div ref={childRef}>
                  <Answer>
                    Our package delivery usually takes anywhere from 2 to 17
                    business days. You will receive an email about your order
                    shipping status whenever it&apos;s ready.
                  </Answer>
                </div>
              </AnswerContainer>
            </QuestionContainer>
            <QuestionContainer expanded={isExpanded(10)}>
              <TitleContainer onClick={() => handleClick(10)}>
                <Question>
                  How do I cancel my subscription if I need to?
                </Question>
                {isExpanded(10) ? <ArrowUp /> : <ArrowDown />}
              </TitleContainer>

              <AnswerContainer expanded={isExpanded(10)}>
                <div ref={childRef}>
                  <Answer>
                    You can cancel your subscription by sending a quick email
                    over to our support team at hello@nova.health, and we’ll
                    handle the cancellation for you, hassle-free.
                  </Answer>
                </div>
              </AnswerContainer>
            </QuestionContainer>
            <QuestionContainer expanded={isExpanded(11)}>
              <TitleContainer onClick={() => handleClick(11)}>
                <Question>Can I get my money back if I don’t like it?</Question>
                {isExpanded(11) ? <ArrowUp /> : <ArrowDown />}
              </TitleContainer>

              <AnswerContainer expanded={isExpanded(11)}>
                <div ref={childRef}>
                  <Answer>
                    Nova offers a 14-day money-back policy. This means you can
                    return your Nova supplements within 14 days of receiving
                    your purchase according to our terms & conditions.
                  </Answer>
                </div>
              </AnswerContainer>
            </QuestionContainer>
          </List>
        </Container>
      </Wrapper>
    </Layout>
  );
};

export default React.memo(FAQ);

const StyledImage = styled(DynamicImage)``;

const UnderscoreLink = styled.a`
  text-decoration: underline !important;
`;

const Wrapper = styled.div`
  flex-grow: 1;
  overflow: hidden;
  padding-top: 48px;
  position: relative;
  z-index: 1;
  min-height: calc(100vh - 416px);
  height: 100%;
  @media ${tablet} {
    z-index: 0;
  }
`;
